<template>
  <v-container class="profile-summary-container">
    <h1 class="primary--text font-weight-bold">
      {{ $t("profile.header.personalData") }}
    </h1>
    <v-row no-gutters>
      <v-col cols="12" md="6" class="px-0 pr-md-2 mt-6 mb-10 ma-md-0">
        <v-card tile elevation="0">
          <v-card-text>
            <!-- <ZonaCardRow /> -->
            <div class="zona-card-row" v-if="user.billingAddress.codInt">
              <v-row>
                <v-col cols="12" md="4">
                  <!-- <span class="text-h6" v-if="user.billingAddress.codInt">{{
                    $t("zonaCardRow.header.cardLogged")
                  }}</span> -->
                  <transition name="flip" v-if="user.billingAddress.codInt">
                    <v-card
                      @click="toggleCard()"
                      v-bind:key="flipped"
                      class="lighten-2 d-flex flex-column rounded card-big"
                    >
                      <!-- <img
                        class="card-img align-self-end text-center"
                        src="/img_layout/gala-card-fronte.png"
                        alt="Gala Card"
                        title="Gala Card"
                        width="100%"
                        v-if="!flipped"
                      /> -->
                      <barcode
                        :value="user.billingAddress.codInt"
                        :options="{
                          format: 'CODE39',
                          margin: 10,
                          height: 50,
                          width: 50,
                          font: 'OCR-B',
                          fontSize: 0,
                          textMargin: -1
                        }"
                        class="align-self-center"
                      />
                      <v-card-text class="primary--text text--darken-1">
                        {{ $t("zonaCardRow.header.cardLogged") }} <br />
                        <span class="d-block card-info text-h3 default--text"
                          >{{ cart.user.firstName }}
                          {{ cart.user.lastName }}</span
                        >
                        {{ $t("zonaCardRow.text.cardNumber") }}<br />
                        <span class="d-block card-info text-h3 default--text">
                          {{ user.billingAddress.codInt }}
                        </span>
                        <span style="font-size: 11px" v-if="!flipped"
                          >Fai tap sulla carta per visualizzare il barcode</span
                        >
                      </v-card-text>
                      <!-- <v-icon
                  v-if="flipped"
                  style="position: absolute; bottom: 0px; right: 0"
                  @click.stop="openFidelityModal()"
                  >$fullscreen</v-icon
                > -->
                    </v-card>
                  </transition>
                </v-col>
              </v-row>
            </div>
            <!-- <div v-else>
              <v-btn color="secondary" depressed @click="regenerateCard">
                Richiedi una Card
              </v-btn>
            </div> -->

            <!-- <div>{{ user.firstName }} {{ user.lastName }}</div> -->
            <!-- <div>
              {{ $t("profile.birthDate") }}:
              {{ $dayjs(user.person.birthDate).format("DD/MM/YYYY") }}
            </div> -->
            <div class="my-1">
              {{ $t("profile.address.address") }}: {{ formattedAddress }}
            </div>
            <div class="my-1">
              {{ $t("profile.contacts.mobilePhone") }}: {{ cart.user.phone }}
            </div>
            <div class="my-1">
              {{ $t("profile.contacts.workPhone") }}:
              {{ cart.user.contact.workPhone }}
            </div>

            <div v-if="user.email" class="my-1">
              {{ $t("profile.email.email") }}: {{ user.email }}
            </div>
            <div class="error--text font-weight-bold my-1" v-if="!user.email">
              {{ $t("profile.email.noEmail") }}
            </div>
            <div class="my-1">
              {{ $t("profile.contacts.emailCertified") }}:
              {{ cart.user.person.emailCertified }}
            </div>
            <div class="my-1">
              {{ $t("profile.contacts.vatSdiCode") }}:
              {{ cart.user.person.vatSdiCode }}
            </div>
            <div class="mt-4">
              <a
                class="font-weight-bold"
                href="/profile/profile-update"
                @click="clicked"
                ><span class="mr-2">{{ $t("profile.edit.data") }}</span
                ><v-icon color="primary" x-small>$edit</v-icon></a
              >
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="6" class="px-0 pl-md-2">
        <v-card tile elevation="0" class="primary--text">
          <v-card-text>
            <h3 class="primary--text mb-2">{{ $t("profile.password") }}</h3>
            <div>****************</div>
            <div class="mt-4">
              <a class="font-weight-bold mt-4" href="/profile/changepassword"
                ><span class="mr-2">{{ $t("profile.edit.password") }}</span
                ><v-icon color="primary" x-small>$edit</v-icon></a
              >
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        v-if="$platform_is_cordova && isBetaTester"
        class="mt-8 development-link"
      >
        <a :href="developmentLink">Apri versione di test </a></v-col
      >
      <v-col
        v-if="$platform_is_cordova && isBetaTester"
        class="mt-8 development-link"
      >
        <a :href="testZonaLink">Apri versione di test GALA </a></v-col
      >
      <v-col v-if="$platform_is_cordova" cols="12">
        <div class="section default--text last links mt-5 text-center">
          <i18n
            path="footer.version"
            tag="span"
            class="my-1 child pl-2 text-center"
          >
            {{ version }}
          </i18n>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<style lang="scss">
.flip-enter-active {
  transition: all 0.4s ease;
}

.flip-leave-active {
  display: none;
}

.flip-enter,
.flip-leave {
  transform: rotateY(180deg);
  opacity: 0;
}
.prima-card-row {
  .card-img {
    width: 80px;
    height: 45px;
  }
  .card-big {
    height: 210px;
  }
  .card-small {
    height: 100px;
    &.top {
      margin-bottom: 10px;
    }
  }
  .purse-small {
    font-size: 1.4rem;
  }
}
.profile-summary-container {
  .v-card {
    background-color: #f4f4f4 !important;
  }
  .v-card__text {
    div {
      font-size: 14px;
      color: #333 !important;
    }
  }
  a {
    text-decoration: none;
  }
  .development-link {
    text-decoration: underline !important;
    font-weight: 500;
  }
}
</style>
<script>
import RegistrationService from "@/service/abbondanzaRegistrationService";
import clickHandler from "~/mixins/clickHandler";
import { mapGetters, mapState } from "vuex";

export default {
  data() {
    return {
      flipped: false,
      oldBrightness: null
    };
  },
  name: "ProfileSummary",
  mixins: [clickHandler],
  computed: {
    ...mapGetters({
      user: "cart/getUser",
      isBetaTester: "cart/isBetaTester"
    }),
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    formattedAddress() {
      return `${this.user.billingAddress.address1}, ${this.user.billingAddress.postalcode} ${this.user.billingAddress.city} (${this.user.billingAddress.province})`;
    },
    developmentLink() {
      //da testare se device esiste veramente in vue.js
      // eslint-disable-next-line no-undef
      let platform = device ? device.platform.toLowerCase() : "ios";
      return "/mobile2/develop/" + platform + "/index.html";
    },
    testZonaLink() {
      //da testare se device esiste veramente in vue.js
      // eslint-disable-next-line no-undef
      let platform = device ? device.platform.toLowerCase() : "ios";
      return (
        "https://qa.galasupermercatimobile.it/mobile2/develop/test/" +
        platform +
        "/index.html"
      );
    },
    version() {
      return global.config.version;
    }
  },
  methods: {
    async regenerateCard() {
      await RegistrationService.regenerateCard();
    },
    toggleCard() {
      var _this = this;
      this.flipped = !this.flipped;
      if (this.flipped) {
        // eslint-disable-next-line no-undef
        cordova.plugins.brightness.getBrightness(
          function(data) {
            _this.oldBrightness = data;
            // eslint-disable-next-line no-undef
            cordova.plugins.brightness.setBrightness(1);
          },
          function(err) {
            console.log("error: " + err);
          }
        );
      } else {
        // eslint-disable-next-line no-undef
        cordova.plugins.brightness.setBrightness(_this.oldBrightness);
      }
    }
  }
};
</script>
